import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Form, FormWrapper, Modal, Input } from 'components/common';
import { useForm } from 'react-hook-form';
import AccountInformation from './AccountInformation';
import ContactInformation from './ContactInformation';
import {
  addVendor,
  getVendorDetailsById,
  updateVendor,
  activeVendor
} from 'redux/APICaller/vendorManagementAPIs';
import { INTERNAL_SERVER_ERROR, SUCCESS } from 'constants/apiStatusCodes';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { useNavigate, useParams } from 'react-router-dom';
import BillingAddress from './BillingAddress';
import APIAccess from './APIAccess';
import { useQuery } from '@tanstack/react-query';
import HelpIcon from '@mui/icons-material/Help';
import styles from './styles';

const AdminVendor = () => {
  const navigate = useNavigate();
  const { id, isCustomer, activateDeactivate } = useParams();
  const [open, setOpen] = useState(false);
  const [apiCalling, setApiCalling] = useState(false);
  const [activeted, setActiveted] = useState(false);
  const [vendorDetails, setVendorDetails] = useState('');
  const [dontShowPass, setDontShowPass] = useState({ password: true, resetpassword: true });

  const {
    control,
    formState: { errors },
    trigger,
    getValues,
    watch,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      organization_name: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      contact_full_address: '',
      country_id: '',
      state_id: '',
      city_id: '',
      zip_code: '',
      billing_full_address: '',
      apiAccess: true,
      password: '',
      confirm_password: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const forms = id
    ? [
        {
          label: 'Account Information',
          form: AccountInformation
        },
        {
          label: 'Contact Information',
          form: ContactInformation
        },
        {
          label: 'Billing Address',
          form: BillingAddress
        },
        {
          label: 'API Access',
          form: APIAccess
        }
      ]
    : [
        {
          label: 'Account Information',
          form: AccountInformation
        },
        {
          label: 'Contact Information',
          form: ContactInformation
        }
      ];

  /**
   * Get Vendor information
   */
  const { data = {}, isLoading } = useQuery({
    queryKey: ['userData', id],
    queryFn: async () => {
      const res = await getVendorDetailsById({ id, isCustomer });
      if (res?.status === SUCCESS) {
        setVendorDetails(res?.data);
        reset({
          ...res?.data,
          apiAccess: true,
          password: isCustomer === 'true' ? '123456A' : '',
          confirm_password: isCustomer === 'true' ? '123456A' : ''
        });
      } else if (res?.status !== INTERNAL_SERVER_ERROR) showErrorToast(res?.data?.message);
      else showErrorToast();
      return res;
    },
    enabled: !!id
  });

  const addVendorApi = async (payload) => {
    setApiCalling(true);
    const res = await addVendor({
      ...payload
    });
    if (res?.status == SUCCESS) {
      showSuccessToast(res?.data?.msg || res?.data?.message);
      navigate('/vendor-list');
    } else {
      if (res?.status !== INTERNAL_SERVER_ERROR) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setApiCalling(false);
  };

  const updateVendorDetailsApi = async (payload) => {
    setApiCalling(true);
    const res = await updateVendor({
      ...payload
    });
    if (res?.status == SUCCESS) {
      showSuccessToast(res?.data?.msg || res?.data?.message);
      if (isCustomer === 'false') navigate(`/vendor/${id}/true`);
    } else {
      if (res?.status !== INTERNAL_SERVER_ERROR) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setApiCalling(false);
  };
  const activeVendorApi = async (payload) => {
    setApiCalling(true);
    const res = await activeVendor({
      ...payload
    });
    if (res?.status == SUCCESS) {
      setOpen(false);
      setActiveted(true);
      showSuccessToast(res?.data?.msg || res?.data?.message);
    } else {
      if (res?.status !== INTERNAL_SERVER_ERROR) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setApiCalling(false);
  };

  const finishExecution = (res) => {
    if (res) {
      if (id) {
        const obj = getValues();
        if (isCustomer === 'true' && watch('password') === '123456A') {
          delete obj['password'];
          delete obj['confirm_password'];
        }

        updateVendorDetailsApi({ ...obj, id, isCustomer });
      } else addVendorApi(getValues());
    }
  };

  const {
    control: remarksControl,
    formState: { errors: remarksErrors },
    trigger: remarksTrigger,
    getValues: remarksGetValues
  } = useForm({
    defaultValues: {
      remarks: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  return (
    <FormWrapper
      disabled={activeted}
      loading={isLoading || apiCalling}
      heading="Vendor Details"
      buttonLabel={activateDeactivate?.toString() ? 'Activate' : id ? 'Save Changes' : 'Save'}
      buttonClick={() => {
        activateDeactivate?.toString()
          ? setOpen(true)
          : trigger().then((res) => res && finishExecution(res));
      }}>
      {forms?.map(({ label, form: FormElement }, index) => (
        <Box key={index}>
          <Form heading={label}>
            <FormElement
              vendorDetails={vendorDetails}
              disabled={activateDeactivate?.toString()}
              control={control}
              errors={errors}
              id={id}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              loading={isLoading || apiCalling}
              dontShowPass={dontShowPass}
              setDontShowPass={setDontShowPass}
            />
          </Form>
        </Box>
      ))}

      <Modal
        open={open}
        handleClose={() => !apiCalling && setOpen(false)}
        // heading="Deactivate Vendor"
        onSuccess={() =>
          remarksTrigger().then((res) => res && activeVendorApi({ id, ...remarksGetValues() }))
        }
        buttonLabel="Confirm">
        <Box sx={styles.modalContainer}>
          {/* <HelpIcon sx={styles.modalIcon} /> */}
          <Box
            component="img"
            sx={{
              width: { xs: 60, sm: 73 }
            }}
            src="/help.png"
          />
          <Box>Are you sure you want to approve this vendor?</Box>
          <Input
            name="remarks"
            label="Remarks"
            isRequired
            control={remarksControl}
            errors={remarksErrors}
            rules={{ required: 'This is a required field' }}
          />
        </Box>
      </Modal>
    </FormWrapper>
  );
};

export default AdminVendor;
