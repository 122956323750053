const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflowY: 'overlay',
    gap: '0px',

    '& .Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'custom.disabled !important',
      pointerEvent: 'all'
    }
  },

  mainTab: {
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    borderBottom: '2px solid #EAEBEE',
    boxShadow: 'none',
    color: 'black',

    '& .MuiAccordionDetails-root': {
      padding: '0 !important'
    },

    '&.MuiAccordion-root:before': {
      padding: '0 !important',
      opacity: '0 !important'
    },

    '& .Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'custom.disabled !important',
      pointerEvent: 'all'
    }
  },

  headingWrapper: {
    boxSizing: 'border-box',
    position: 'relative',
    margin: '0.6em 0',
    gap: '16px',
    padding: {
      xs: '0 16px',
      md: '0 20px'
    }
  },

  icon: {
    color: 'black',
    fontSize: '1.5em',
    fontWeight: 500
  },

  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    boxSizing: 'border-box',
    transition: '.5s',
    fontSize: {
      xs: '13.5px',
      sm: '14.5px'
    },
    fontWeight: 400
  },

  headingIcon: {
    fontSize: '1em',
    color: 'black'
  },

  label: {
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    cursor: 'pointer',
    letterSpacing: 0.8,
    margin: '0.5em',
    padding: {
      xs: '10px 8px 10px 3em',
      md: '12px 8px 12px 2.6em'
    },
    '&:hover': {
      backgroundColor: '#F7F8FA'
    }
  },

  leftBar: {
    position: 'absolute',
    top: 2,
    left: 2,
    borderRadius: '8px',
    backgroundColor: 'red',
    height: '90%',
    border: '2px solid #242425'
  }
};

export default styles;
