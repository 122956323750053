import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import styles, { shrinkSx } from '../styles';

const DefaultSkeletton = ({
  label,
  animation = 'wave',
  children,
  customStyles,
  shrink = false,
  sx,
  loading = false,
  ...rest
}) => {
  if (!loading && children) {
    return <>{children}</>;
  }

  return (
    <Box sx={{ ...styles.wrapper, ...customStyles }}>
      <Skeleton sx={{ ...sx, ...shrinkSx(shrink) }} animation={animation} {...rest}>
        {children}
      </Skeleton>
    </Box>
  );
};

export default DefaultSkeletton;
