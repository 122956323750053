import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  suppliersName: '',
  suppliersCode: '',
  gst: '',
  fullAddress: '',
  state: '',
  city: '',
  zipCode: '',
  status: ''
};

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    supplier: (state, action) => {
      state = Object.assign(state, action.payload);
    }
  }
});

export const { supplier } = supplierSlice.actions;
export default supplierSlice.reducer;
