import { configureStore } from '@reduxjs/toolkit';
import userProfileSlice from './UserSlice/userProfileSlice';
import facilitySlice from './WarehouseSlice/facilitySlice';
import supplierSlice from './WarehouseSlice/supplierSlice';
import warehouseSlice from './WarehouseSlice/warehouseSlice';
import appReducer from './appSlice';
import authReducer from './authSlice';
import createUserSlice from './createUserSlice';
import toastReducer from './showMessageSlice';

export const store = configureStore({
  reducer: {
    authStore: authReducer,
    appStore: appReducer,
    showMessageStore: toastReducer,
    createUser: createUserSlice,
    user: userProfileSlice,
    facility: facilitySlice,
    warehouse: warehouseSlice,
    supplier: supplierSlice
  }
});
