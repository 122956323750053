const styles = {
  wrapper: {
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    },

    '& .MuiInputBase-input': {
      padding: '4px 6px 5px'
    }
  },

  iconBtn: {
    padding: 0
  },

  input: {
    position: 'relative'
    // height: {
    //   xs: '34px',
    //   sm: '38px'
    // }
  },

  label: {
    '& .MuiFormLabel-asterisk': {
      color: 'red'
    }
  },

  icon: {
    fontSize: {
      xs: '21px',
      sm: '24px'
    }
  }
};

export default styles;
