import { GettingStarted, SignIn, VendorList, AddVendor } from 'components/features';

const router = {
  authentication: [
    {
      path: '/sign-in',
      element: SignIn
    }
  ],
  defaultRoutes: [
    {
      path: '/',
      element: GettingStarted
    },
    {
      path: '/vendor-list',
      element: VendorList
    },
    {
      path: '/vendor',
      element: AddVendor
    },
    {
      path: '/vendor/:id/:isCustomer',
      element: AddVendor
    },
    {
      path: '/vendor/:id/:isCustomer/:activateDeactivate',
      element: AddVendor
    }
  ]
};

const { authentication, defaultRoutes } = router;

export { authentication, defaultRoutes };
