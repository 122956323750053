import Dashboard from 'components/Dashboard';
import { Spinner } from 'components/common';
import { Error } from 'components/features';
import { authentication, defaultRoutes } from 'constants/routes';
import { AuthLayout } from 'layout';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

const AppRouter = ({ facilityFetched }) => {
  const token = localStorage.getItem('wms_access_token');
  const isLoggedIn = !!token;

  return (
    <Routes>
      {authentication?.map(({ path, element: Element }, index) => {
        return (
          <Route
            key={index}
            path={path}
            element={
              isLoggedIn ? (
                <Navigate to="/" />
              ) : (
                <AuthLayout>
                  <Element />
                </AuthLayout>
              )
            }
          />
        );
      })}

      {defaultRoutes?.map(({ path, element: Element }, index) => {
        return (
          <Route
            key={index}
            path={path}
            element={
              <PrivateRoute>
                {facilityFetched ? (
                  <Dashboard>
                    <Element />
                  </Dashboard>
                ) : (
                  <Spinner />
                )}
              </PrivateRoute>
            }
          />
        );
      })}
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default AppRouter;
