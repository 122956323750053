import { Box } from '@mui/material';
import { Radio } from 'components/common';
import styles from '../styles';

const APIAccess = ({ control, loading = false, disabled }) => {
  return (
    <Box>
      <Radio
        name="apiAccess"
        label="API"
        disabled={disabled}
        loading={loading}
        control={control}
        customStyles={styles.radioButton}
        options={[
          {
            label: 'Enable',
            value: true
          },
          {
            label: 'Disable',
            value: false
          }
        ]}
      />
    </Box>
  );
};

export default APIAccess;
