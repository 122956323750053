/**
 * Stepper List to manage items in the stepper
 */

export const stepperList = [
  {
    heading: 'Admin Control',
    subHeadings: [
      {
        label: 'Vendor Management',
        route: '/vendor-list'
      }
    ],
    role: 'master',
    icon: '/box.svg'
  }
];

export const dummyRoles = ['ROLE_inbound', 'ROLE_returns'];
