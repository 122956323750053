import moment from 'moment';

function subtractOneFromArrayAtIndex1(arr) {
  if (arr.length >= 2) {
    arr[1] -= 1;
  }
  return arr;
}

export const getFormattedDate = (dateString, dateFormat = 'dddd,MMMM Do YYYY,h:mm:ss a') => {
  let momentDate;
  if (Array.isArray(dateString)) {
    if (dateString?.length > 6) {
      momentDate = moment(subtractOneFromArrayAtIndex1(dateString?.slice(0, 6)));
    } else {
      momentDate = moment(subtractOneFromArrayAtIndex1(dateString));
    }
  } else momentDate = moment(dateString);

  if (!momentDate?.isValid()) {
    return '';
  }

  const formattedDate = momentDate.format(dateFormat);
  return formattedDate;
};

export const capitalizeFirstLetter = (str) => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  else return '';
};

export const stringTrimmer = (data, dataLength = 25) => {
  if (data?.length > dataLength + 1) {
    return data.substring(0, dataLength) + '...';
  } else {
    return data;
  }
};

export const filteredArray = (originalArray = []) => {
  const filteredArray = originalArray.filter((item) => {
    if (item === false || item === null) {
      return false;
    }
    if (typeof item === 'string' && item.trim() === '') {
      return false;
    }
    return true;
  });
  return filteredArray;
};

export const generateuuid = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};
