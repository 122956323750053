const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: '100%'
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
  },

  email: {
    color: 'primary.main',
    cursor: 'pointer',
    transition: 'all 200ms',

    '&:hover': {
      textDecoration: 'underline'
    }
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 2
  },

  status: {
    fontSize: '13px'
  },

  action: {
    width: '120px'
  },

  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 3
  },

  modalIcon: {
    fontSize: '50px'
  }
};

export default styles;
