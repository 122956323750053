export const SUCCESS = 200;

export const SUCCESS_CREATED = 201;

export const BAD_REQUEST = 400;

export const UNAUTHORIZED = 401;

export const NOT_FOUND = 404;

export const INTERNAL_SERVER_ERROR = 500;
