const styles = {
  mainWrapper: {
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    overflow: 'auto',
    gap: {
      xs: '16px',
      sm: '25px'
    },
    position: 'absolute',
    minWidth: {
      xs: 400,
      sm: 550,
      md: 650
    },
    width: {
      xs: 400,
      sm: 550,
      md: 650
    },
    height: 'min-content',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '20px'
  },

  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: {
      xs: '15px 25px 0px',
      sm: '20px 30px 0px'
    }
  },

  heading: {
    fontSize: {
      xs: '16px',
      sm: '20px'
    },
    fontWeight: 600
  },

  iconWrapper: {
    padding: '3px',
    backgroundColor: '#D6D6D6',
    '&:hover': {
      backgroundColor: '#D6D6D6'
    }
  },

  closeIcon: {
    color: 'white',
    fontSize: {
      xs: '26px',
      sm: '20px'
    }
  },

  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#D6D6D6'
  },

  children: {
    padding: {
      xs: '15px 25px 0px',
      sm: '20px 30px 0px'
    }
  },

  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: {
      xs: '0px 25px 15px',
      sm: '0px 30px 20px'
    }
  },

  button: {
    height: '38px',
    width: 'max-content',
    borderRadius: '5px'
  }
};

export default styles;
