/**
 * Function to return error message for form components
 * @param {string} name
 * @param {FieldErrors} errors
 * @returns error message for the field
 */

export const getError = (name, errors) => {
  if (!Object.keys(errors)?.length) return undefined;

  return name
    .split('.')
    ?.map((item) => item.replaceAll('[', '').replaceAll(']', ''))
    ?.reduce((prev, curr) => (prev ? prev[curr] : prev), errors);
};

export const handleDownloadCsv = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.click();
  link.remove();
};

// 1. Search ACN
export const SearchACNHeading = 'Consignment Information';
export const SearchACNDescription = 'Accept/Reject Consignment';

//2. Allow Gate Out
export const AllowGateOutHeading = 'Trailer Information';
export const AllowGateOutDescription = 'List of trailer released from dock';

export const shortenString = (longString, length = 25) => {
  if (longString?.length <= length) {
    return longString;
  } else {
    return longString?.substring(0, length) + '...';
  }
};
