import { useState, useEffect } from 'react';

const usePlatformAndResolution = () => {
  const [platform, setPlatform] = useState('unknown');
  const [screenResolution, setScreenResolution] = useState('100%');

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Windows')) {
      setPlatform('windows');
    } else if (userAgent.includes('Linux')) {
      setPlatform('ubuntu');
    }

    const mediaQuery = window.matchMedia('(min-width: 1280px)');
    if (mediaQuery.matches) {
      setScreenResolution('125%');
    }
  }, []);

  return { platform, screenResolution };
};

export default usePlatformAndResolution;
