const styles = {
  wrapper: {
    textTransform: 'capitalize',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: 'custom.label'
    },
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    gap: '20px',
    m: 0
  },

  switch: {
    width: 51,
    height: 28.5,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 0.3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(19.5px)',
        color: 'white',
        '& + .MuiSwitch-track': {
          backgroundColor: 'primary.main',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          cursor: 'not-allowed',
          pointerEvent: 'all',
          opacity: 0.5
        }
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        cursor: 'not-allowed',
        pointerEvent: 'all',
        color: 'custom.disabled'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        cursor: 'not-allowed',
        pointerEvent: 'all',
        opacity: 0.7
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 24,
      height: 24
    },
    '& .MuiSwitch-track': {
      borderRadius: 31 / 2,
      backgroundColor: 'custom.disabled',
      opacity: 1,
      transition: 'background-color 0.5s ease'
    },
    '& .Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvent: 'all'
    }
  }
};

export default styles;
