import { INTERNAL_SERVER_ERROR, SUCCESS } from 'constants/apiStatusCodes';
import { useEffect, useState } from 'react';
import { getVendorList } from 'redux/APICaller/vendorManagementAPIs';
import { Modal } from 'components/common';
import { Box } from '@mui/material';
import Table from './table';
import HelpIcon from '@mui/icons-material/Help';
import { enableDisableVendor } from 'redux/APICaller/vendorManagementAPIs';
import { useForm } from 'react-hook-form';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import styles from './styles';

const VendorList = () => {
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [statusObj, setStatusObj] = useState('');
  const { control, setValue, watch, getValues } = useForm({
    defaultValues: {},
    mode: 'all',
    criteriaMode: 'all'
  });
  const [apiCalling, setApiCalling] = useState(false);

  const APICALL = async (page = 1) => {
    setLoading(true);
    const res = await getVendorList({
      page: page ? page - 1 : page,
      size: 15
    });

    if (res.status === SUCCESS) {
      if (page === 1 || page === 'reset') {
        setList([
          {
            page: 1,
            array: res?.data?.list
          }
        ]);
      } else setList([...list, { page, array: res?.data?.list }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status !== INTERNAL_SERVER_ERROR) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  const [defaultValues, setDefaultValues] = useState({});
  const [actionNames, setActionNames] = useState([]);
  const [initialActionValues, setInitialActionValues] = useState([]);

  useEffect(() => {
    if (tableRows?.length) {
      const nameArray = [];
      const valueArray = [];
      const defaultValueObj = {};
      tableRows?.map((item) => {
        defaultValueObj[`${item?.vendor_id}-status`] = '';
        nameArray?.push(`${item?.vendor_id}-action`);
        valueArray?.push(item?.action);
      });
      setActionNames(nameArray);
      setDefaultValues(defaultValueObj);
      setInitialActionValues(valueArray);
    }
  }, [tableRows]);

  const statusChangeApi = async (payload) => {
    setApiCalling(true);
    const res = await enableDisableVendor(payload);
    if (res?.status === SUCCESS) {
      showSuccessToast(res?.data?.message || res?.data?.msg);

      tableRows[payload.itemIndex].status = payload?.status;
      setStatusObj('');
    } else {
      setValue(`${payload?.id}-status`, payload?.status);
      if (res?.status !== INTERNAL_SERVER_ERROR) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setApiCalling(false);
  };

  return (
    <>
      <Table
        initialActionValues={initialActionValues}
        defaultValues={defaultValues}
        actionNames={actionNames}
        tableRows={tableRows}
        pageData={pageData}
        apiCallWithPage={apiCallWithPage}
        loading={loading}
        setStatusObj={setStatusObj}
        control={control}
        setValue={setValue}
        watch={watch}
        getValues={getValues}
      />
      <Modal
        open={statusObj}
        handleClose={() => {
          if (!apiCalling) {
            setValue(`${statusObj?.id}-status`, !statusObj?.status);
            setStatusObj('');
          }
        }}
        onSuccess={() => statusChangeApi(statusObj)}
        disabled={apiCalling}
        buttonLabel="Confirm">
        <Box sx={styles.modalContainer}>
          {/* <HelpIcon sx={styles.modalIcon} /> */}
          <Box
            component="img"
            sx={{
              width: { xs: 60, sm: 73 }
            }}
            src="./help.png"
          />
          <Box>{`Are you sure you want to ${
            statusObj?.status ? 'enable' : 'disable'
          } this vendor ?`}</Box>
        </Box>
      </Modal>
    </>
  );
};

export default VendorList;
