import MatButton from '@mui/material/Button';
import React from 'react';
import styles from './styles';

const Button = ({ label, variant = 'outlined', customStyles, ...rest }) => {
  return (
    <MatButton sx={{ ...styles.wrapper, ...customStyles }} variant={variant} {...rest}>
      {label}
    </MatButton>
  );
};

export default Button;
