import USER_FACILITY_PATHS from '../../constants/APIPaths/Vendor';
import { getAPI, postAPI, putAPI, patchAPI } from '../../helpers/API';

const {
  CHECK_VALID_USER,
  VENDOR_ENABLE_DISABLE,
  GET_VENDOR_DETAILS,
  GET_COUNTRY_LIST,
  GET_STATE_LIST,
  GET_CITY_LIST,
  ACTIVE_VENDOR
} = USER_FACILITY_PATHS;

export function getVendorList(data) {
  return getAPI({
    url: CHECK_VALID_USER,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function checkValidUser(data) {
  return getAPI({
    url: CHECK_VALID_USER,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function enableDisableVendor(data) {
  return patchAPI({
    url: VENDOR_ENABLE_DISABLE.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function addVendor(data) {
  return postAPI({
    url: CHECK_VALID_USER,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function updateVendor(data) {
  return putAPI({
    url: GET_VENDOR_DETAILS.replace(':id', data?.id).replace(':isCustomer', data?.isCustomer),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function activeVendor(data) {
  return putAPI({
    url: ACTIVE_VENDOR.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getCountryList(data) {
  return getAPI({
    url: GET_COUNTRY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getStateList(data) {
  return getAPI({
    url: GET_STATE_LIST.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getCityList(data) {
  return getAPI({
    url: GET_CITY_LIST.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getVendorDetailsById(data) {
  return getAPI({
    url: GET_VENDOR_DETAILS.replace(':id', data?.id).replace(':isCustomer', data?.isCustomer),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
