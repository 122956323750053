/* eslint-disable no-undef */
import axios from 'axios';
import { AUTH_PATHS } from '../constants/apiPaths';
import { CLIENT_ID, GRANT_TYPE_REFRESH_TOKEN } from '../constants/constants';
import { UNAUTHORIZED, SUCCESS, INTERNAL_SERVER_ERROR } from '../constants/apiStatusCodes';
import { generateuuid } from '../utils/index';
const { SIGN_IN } = AUTH_PATHS;

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_KEYCLOAK_BASE_URL
});

const customAxiosAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL
});

const customAxiosFeature = axios.create({
  baseURL: process.env.REACT_APP_FEATURE_BASE_URL
});

const getAccessToken = () => {
  if (localStorage?.getItem('wms_access_token')) return localStorage.getItem('wms_access_token');
  else return '';
};

const postConfig = (isPrivate, isCustomDomain, token, isMultipart) => {
  // eslint-disable-next-line no-constant-condition
  if (isPrivate) {
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : `Bearer ${getAccessToken()}`,
        'Content-Type': isMultipart
          ? 'multipart/form-data'
          : isCustomDomain
          ? 'application/json'
          : 'application/x-www-form-urlencoded',
        facility_id: localStorage?.getItem('facility_id'),
        typeoforder: localStorage?.getItem('order_type'),
        request_id: generateuuid()
      },
      method: 'post'
    };
  } else
    return {
      headers: {
        'Content-Type': isCustomDomain ? 'application/json' : 'application/x-www-form-urlencoded'
      },
      method: 'post'
    };
};

const patchConfig = (isPrivate, isCustomDomain, token, isMultipart) => {
  // eslint-disable-next-line no-constant-condition
  if (isPrivate) {
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : `Bearer ${getAccessToken()}`,
        facility_id: localStorage?.getItem('facility_id'),
        typeoforder: localStorage?.getItem('order_type'),
        request_id: generateuuid(),
        'Content-Type': isMultipart
          ? 'multipart/form-data'
          : isCustomDomain
          ? 'application/json'
          : 'application/x-www-form-urlencoded'
      },
      method: 'patch'
    };
  } else
    return {
      headers: {
        'Content-Type': isCustomDomain ? 'application/json' : 'application/x-www-form-urlencoded'
      },
      method: 'patch'
    };
};

const putConfig = (isPrivate, isCustomDomain, token) => {
  // eslint-disable-next-line no-constant-condition
  if (isPrivate) {
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : `Bearer ${getAccessToken()}`,
        facility_id: localStorage?.getItem('facility_id'),
        typeoforder: localStorage?.getItem('order_type'),
        request_id: generateuuid(),
        'Content-Type': isCustomDomain ? 'application/json' : 'application/x-www-form-urlencoded'
      },
      method: 'put'
    };
  } else
    return {
      headers: {
        'Content-Type': isCustomDomain ? 'application/json' : 'application/x-www-form-urlencoded'
      },
      method: 'put'
    };
};

const getConfig = (isPrivate, isCustomDomain, token) => {
  // eslint-disable-next-line no-constant-condition
  if (isPrivate) {
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : `Bearer ${getAccessToken()}`,
        'Content-Type': isCustomDomain ? 'application/json' : 'application/x-www-form-urlencoded',
        facility_id: localStorage?.getItem('facility_id'),
        typeoforder: localStorage?.getItem('order_type'),
        request_id: generateuuid()
      },
      method: 'get'
    };
  } else
    return {
      headers: {
        'Content-Type': isCustomDomain ? 'application/json' : 'application/x-www-form-urlencoded'
      },
      method: 'get'
    };
};

const getAccessTokenByRefreshToken = async () => {
  return await authAxios({
    ...postConfig(false, true),
    url: `${SIGN_IN}`,
    data: {
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      client_id: CLIENT_ID,
      refresh_token: localStorage.getItem('wms_refresh_token')
    }
  })
    .then((response) => {
      if (response?.status === SUCCESS) {
        return response;
      } else {
        return { status: UNAUTHORIZED };
      }
    })
    .catch((error) => {
      return {
        status: UNAUTHORIZED
      };
    });
};

export const callAPIWithNewToken = async ({
  axiosInstance,
  url,
  data,
  token,
  type,
  isMultipart
}) => {
  let newConfig = {};
  if (type === 'get') newConfig = { ...getConfig(true, true, token) };
  else if (type === 'patch') newConfig = { ...patchConfig(true, true, token), isMultipart };
  else if (type === 'put') newConfig = { ...putConfig(true, true, token) };
  else if (type === 'post') newConfig = { ...postConfig(true, true, token, isMultipart) };
  return await axiosInstance({
    ...newConfig,
    url: `${url}`,
    data,
    ...(type === 'get' ? { params: data } : { data })
  })
    .then((response) => {
      if (response.status === SUCCESS)
        return {
          status: response.status,
          data: response.data
        };
      else
        return {
          status: response.status
        };
    })
    .catch((error) => {
      return {
        status: error?.response?.status || INTERNAL_SERVER_ERROR
      };
    });
};

export const postAPI = async ({
  url,
  data,
  isPrivate = true,
  isCustomDomain = true,
  isCustomDomain2 = false,
  isMultipart = false
}) => {
  let axiosInstance;
  if (isCustomDomain) {
    if (isCustomDomain2) {
      axiosInstance = customAxiosFeature;
    } else axiosInstance = customAxiosAuth;
  } else axiosInstance = authAxios;
  return await axiosInstance({
    ...postConfig(isPrivate, isCustomDomain, null, isMultipart),
    url: `${url}`,
    data
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data
      };
    })
    .catch(async (error) => {
      if (
        error?.response?.status === UNAUTHORIZED &&
        isPrivate &&
        localStorage.getItem('wms_refresh_token')
      ) {
        const accessTokenResponse = await getAccessTokenByRefreshToken();

        if (
          accessTokenResponse?.status === SUCCESS &&
          accessTokenResponse?.data?.access_token &&
          accessTokenResponse?.data?.refresh_token
        ) {
          // Call same api again with new token
          const response = await callAPIWithNewToken({
            axiosInstance,
            url,
            data,
            token: accessTokenResponse?.data?.access_token,
            type: 'post',
            isMultipart
          });

          localStorage.setItem('wms_access_token', accessTokenResponse?.data?.access_token);
          if (accessTokenResponse?.data?.refresh_token)
            localStorage.setItem('wms_refresh_token', accessTokenResponse?.data?.refresh_token);

          if (response?.status === SUCCESS) {
            return response;
          } else {
            if (response?.status === UNAUTHORIZED) {
              // Remove localStorage values and Redirect to login page
              localStorage.clear();
              window.location.reload();
            } else {
              return {
                status: response?.status || INTERNAL_SERVER_ERROR,
                data: {
                  message: ''
                }
              };
            }
          }
        } else {
          // Remove localStorage values and Redirect to login page
          localStorage.clear();
          window.location.reload();
        }
      }
      return {
        status: error?.response?.status || error?.status || 404,
        data: {
          message:
            error?.response?.data?.message ||
            error?.response?.data?.error_description ||
            error?.response?.data?.error ||
            error.message
        }
      };
    });
};
export const patchAPI = async ({
  url,
  data,
  isPrivate = true,
  isCustomDomain = true,
  isCustomDomain2 = false,
  isMultipart = false
}) => {
  let axiosInstance;
  if (isCustomDomain) {
    if (isCustomDomain2) {
      axiosInstance = customAxiosFeature;
    } else axiosInstance = customAxiosAuth;
  } else axiosInstance = authAxios;
  return await axiosInstance({
    ...patchConfig(isPrivate, isCustomDomain, null, isMultipart),
    url: `${url}`,
    data
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data
      };
    })
    .catch(async (error) => {
      if (
        error?.response?.status === UNAUTHORIZED &&
        isPrivate &&
        localStorage.getItem('wms_refresh_token')
      ) {
        const accessTokenResponse = await getAccessTokenByRefreshToken();

        if (
          accessTokenResponse?.status === SUCCESS &&
          accessTokenResponse?.data?.access_token &&
          accessTokenResponse?.data?.refresh_token
        ) {
          // Call same api again with new token
          const response = await callAPIWithNewToken({
            axiosInstance,
            url,
            data,
            token: accessTokenResponse?.data?.access_token,
            type: 'patch',
            isMultipart
          });

          localStorage.setItem('wms_access_token', accessTokenResponse?.data?.access_token);
          if (accessTokenResponse?.data?.refresh_token)
            localStorage.setItem('wms_refresh_token', accessTokenResponse?.data?.refresh_token);

          if (response?.status === SUCCESS) {
            return response;
          } else {
            if (response?.status === UNAUTHORIZED) {
              // Remove localStorage values and Redirect to login page
              localStorage.clear();
              window.location.reload();
            } else {
              return {
                status: response?.status || INTERNAL_SERVER_ERROR,
                data: {
                  message: ''
                }
              };
            }
          }
        } else {
          // Remove localStorage values and Redirect to login page
          localStorage.clear();
          window.location.reload();
        }
      }
      return {
        status: error?.response?.status || error?.status || 404,
        data: {
          message:
            error?.response?.data?.message ||
            error?.response?.data?.error_description ||
            error?.response?.data?.error ||
            error.message
        }
      };
    });
};

export const putAPI = async ({
  url,
  data,
  isPrivate = true,
  isCustomDomain = true,
  isCustomDomain2 = false
}) => {
  let axiosInstance;
  if (isCustomDomain) {
    if (isCustomDomain2) {
      axiosInstance = customAxiosFeature;
    } else axiosInstance = customAxiosAuth;
  } else axiosInstance = authAxios;

  return await axiosInstance({
    ...putConfig(isPrivate, isCustomDomain),
    url: `${url}`,
    data
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data
      };
    })
    .catch(async (error) => {
      if (
        error?.response?.status === UNAUTHORIZED &&
        isPrivate &&
        localStorage.getItem('wms_refresh_token')
      ) {
        const accessTokenResponse = await getAccessTokenByRefreshToken();

        if (
          accessTokenResponse?.status === SUCCESS &&
          accessTokenResponse?.data?.access_token &&
          accessTokenResponse?.data?.refresh_token
        ) {
          // Call same api again with new token
          const response = await callAPIWithNewToken({
            axiosInstance,
            url,
            data,
            token: accessTokenResponse?.data?.access_token,
            type: 'put'
          });

          localStorage.setItem('wms_access_token', accessTokenResponse?.data?.access_token);
          if (accessTokenResponse?.data?.refresh_token)
            localStorage.setItem('wms_refresh_token', accessTokenResponse?.data?.refresh_token);

          if (response?.status === SUCCESS) {
            return response;
          } else {
            if (response?.status === UNAUTHORIZED) {
              // Remove localStorage values and Redirect to login page
              localStorage.clear();
              window.location.reload();
            } else {
              return {
                status: response?.status || INTERNAL_SERVER_ERROR,
                data: {
                  message: ''
                }
              };
            }
          }
        } else {
          // Remove localStorage values and Redirect to login page
          localStorage.clear();
          window.location.reload();
        }
      }
      return {
        status: error?.response?.status || error?.status || 404,
        data: {
          message:
            error?.response?.data?.message ||
            error?.response?.data?.error_description ||
            error?.response?.data?.error ||
            error.message
        }
      };
    });
};

export const getAPI = async ({
  url,
  data,
  isPrivate = true,
  isCustomDomain = true,
  isCustomDomain2 = false
}) => {
  let axiosInstance;
  if (isCustomDomain) {
    if (isCustomDomain2) {
      axiosInstance = customAxiosFeature;
    } else axiosInstance = customAxiosAuth;
  } else axiosInstance = authAxios;
  return await axiosInstance({
    ...getConfig(isPrivate, isCustomDomain),
    url: `${url}`,
    params: data
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data
      };
    })
    .catch(async (error) => {
      if (
        error?.response?.status === UNAUTHORIZED &&
        isPrivate &&
        localStorage.getItem('wms_refresh_token')
      ) {
        const accessTokenResponse = await getAccessTokenByRefreshToken();

        if (
          accessTokenResponse?.status === SUCCESS &&
          accessTokenResponse?.data?.access_token &&
          accessTokenResponse?.data?.refresh_token
        ) {
          // Call same api again with new token
          const response = await callAPIWithNewToken({
            axiosInstance,
            url,
            data,
            token: accessTokenResponse?.data?.access_token,
            type: 'get'
          });

          localStorage.setItem('wms_access_token', accessTokenResponse?.data?.access_token);
          if (accessTokenResponse?.data?.refresh_token)
            localStorage.setItem('wms_refresh_token', accessTokenResponse?.data?.refresh_token);

          if (response?.status === SUCCESS) {
            return response;
          } else {
            if (response?.status === UNAUTHORIZED) {
              // Remove localStorage values and Redirect to login page
              localStorage.clear();
              window.location.reload();
            } else {
              return {
                status: response?.status || INTERNAL_SERVER_ERROR,
                data: {
                  message: ''
                }
              };
            }
          }
        } else {
          // Remove localStorage values and Redirect to login page
          localStorage.clear();
          window.location.reload();
        }
      }
      return {
        status: error?.response?.status || error?.status || 404,
        data: {
          message:
            error?.response?.data?.message ||
            error?.response?.data?.error_description ||
            error?.response?.data?.error ||
            error.message
        }
      };
    });
};
