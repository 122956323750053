const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-0.5em'
  },

  icon: {
    fontSize: '21px',
    color: 'error.main'
  },

  message: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    color: 'error.main'
  },

  toolTip: {
    fontSize: '14px',
    textAlign: 'center',
    maxWidth: '300px',
    wordSpacing: 1.2
  }
};

export default styles;
