const styles = {
  infoIconBtn: {
    position: 'absolute',
    right: -10,

    top: {
      xs: -25,
      sm: -35
    },
    color: 'custom.label',
    cursor: 'pointer'
  },

  infoIcon: {
    fontSize: { xs: '14px', sm: '18px' }
  },

  summary: {
    fontSize: {
      xs: '9px',
      sm: '12px'
    },
    width: {
      xs: '250px',
      sm: '300px'
    },
    padding: '0.8em',
    backgroundColor: 'white'
  }
};

export default styles;
