const styles = {
  wrapper: {
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '500px',
    wordSpacing: 1.2,
    overflow: 'hidden'
  }
};

export default styles;
