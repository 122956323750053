import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, IconButton } from '@mui/material';
import {
  Dropdown,
  MainContent,
  Pagination,
  Spinner,
  Switch,
  TableDataRenderer,
  Tooltip
} from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { INTERNAL_SERVER_ERROR, SUCCESS } from 'constants/apiStatusCodes';
import { enableDisableVendor } from 'redux/APICaller/vendorManagementAPIs';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import styles from './styles';

const VendorList = ({
  actionNames,
  tableRows,
  loading,
  apiCallWithPage,
  pageData,
  defaultValues,
  initialActionValues,
  setStatusObj,
  control,
  setValue,
  watch,
  getValues
}) => {
  const navigate = useNavigate();

  const [preValueArray, setPreValueArray] = useState(watch(Object.keys(defaultValues)));
  const [actionPreValueArray, setActionPreValueArray] = useState([]);

  useEffect(() => {
    setActionPreValueArray(initialActionValues);
  }, [initialActionValues]);

  const findFirstMismatchIndex = (arr1, arr2) => {
    const minLength = Math.min(arr1.length, arr2.length);

    for (let i = 0; i < minLength; i++) {
      if (arr1[i] !== arr2[i]) {
        return i; // Found a mismatch, return the index
      }
    }

    // return 'SAME' if they are the same
    return 'SAME';
  };

  const updateItemStatusByIndex = (itemIndex) => {
    const tempList = [...tableRows];

    if (tempList[itemIndex]?.status !== getValues(`${tempList[itemIndex]?.vendor_id}-status`))
      setStatusObj({
        id: tempList[itemIndex]?.vendor_id,
        status: getValues(`${tempList[itemIndex]?.vendor_id}-status`),
        itemIndex: itemIndex
      });
  };

  const valueArray = watch(Object.keys(defaultValues));

  useEffect(() => {
    const mismatchIndex = findFirstMismatchIndex(preValueArray, valueArray);
    if (mismatchIndex !== 'SAME') updateItemStatusByIndex(mismatchIndex);
    setPreValueArray(valueArray);
  }, [valueArray.toString()]);

  const updateItemActionByIndex = (itemIndex) => {
    const tempList = [...tableRows];
    const id = tempList[itemIndex]?.vendor_id;

    const is_setup_complete = tempList[itemIndex]?.is_setup_complete;

    const value = getValues(`${tempList[itemIndex]?.vendor_id}-action`);

    navigate(`/vendor/${id}/${is_setup_complete}/${value}`);
  };

  // Active/Deactive
  const actionValueArray = watch(actionNames);

  useEffect(() => {
    if (actionPreValueArray?.length) {
      const mismatchIndex = findFirstMismatchIndex(actionPreValueArray, actionValueArray);

      if (mismatchIndex !== 'SAME') updateItemActionByIndex(mismatchIndex);
      setActionPreValueArray(valueArray);
    }
  }, [actionValueArray.toString()]);

  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const columnDefs = [
    {
      field: 'vendor_name',
      headerName: 'Vendor Name',
      flex: isWebView ? 1 : 0,
      minWidth: 180,
      cellRenderer: ({ data }) => (
        <Box>
          <Tooltip title={data?.vendor_name || 'No data'} dataLength={15} />
        </Box>
      )
    },
    {
      field: 'organization_name',
      headerName: 'Organisation Name',
      flex: isWebView ? 1 : 0,
      minWidth: 180,

      cellRenderer: ({ data }) => (
        <Box>
          <Tooltip title={data?.organization_name || 'No data'} dataLength={15} />
        </Box>
      )
    },
    {
      field: 'email',
      headerName: 'Email ID',
      minWidth: 230,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Box sx={styles.email}>
          <Tooltip title={data?.email} dataLength={25} />
        </Box>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: isWebView ? 1 : 0,
      minWidth: 200,
      cellRenderer: ({ data }) => {
        return (
          <Box sx={styles.container}>
            <Box sx={styles.status}>Disable</Box>
            <Switch
              name={`${data?.vendor_id}-status`}
              control={control}
              disabled={!data?.is_setup_complete}
            />
            <Box sx={styles.status}>Enable</Box>
          </Box>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Action',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box sx={styles.container}>
            <Box sx={styles.action}>
              <Dropdown
                name={`${data?.vendor_id}-action`}
                control={control}
                options={[
                  { value: true, label: 'Active' },
                  { value: false, label: 'Deactive' }
                ]}
                disabled={data?.action || !data?.is_setup_complete}
              />
            </Box>
            <IconButton
              onClick={() => navigate(`/vendor/${data?.vendor_id}/${data?.is_setup_complete}`)}>
              <EditOutlinedIcon />
            </IconButton>
          </Box>
        );
      }
    }
  ];

  const onGridReady = () => {
    tableRows?.map((item) => {
      setValue(`${item?.vendor_id}-status`, item?.status);
      setValue(`${item?.vendor_id}-action`, item?.action);
    });
  };

  return (
    <MainContent
      heading="Vendor List"
      navigateLabel="Add Vendor"
      navigateClick={() => {
        navigate('/vendor');
      }}>
      <Box sx={styles.wrapper}>
        {loading ? (
          <Spinner />
        ) : (
          <TableDataRenderer list={tableRows} columnDefs={columnDefs} onGridReady={onGridReady} />
        )}
        {/* <Box sx={styles.paginationWrapper}>
          <Pagination
            count={pageData.maxPage}
            page={pageData.currentPage}
            onChange={(e, pageNumber) => apiCallWithPage(pageNumber)}
          />
        </Box> */}
      </Box>
    </MainContent>
  );
};

export default VendorList;
