import { Box } from '@mui/material';
import { Input, Password } from 'components/common';
import {
  passwordErrors,
  passwordMapping,
  passwordValidator,
  phoneNumberValidator,
  required
} from 'components/common/commonFunctions';
import { useParams } from 'react-router-dom';
import styles from '../styles';

const ContactInformation = ({
  control,
  errors,
  loading = false,
  id,
  getValues,
  disabled,
  setValue,
  dontShowPass,
  setDontShowPass,
  watch
}) => {
  const { isCustomer } = useParams();

  return (
    <Box sx={styles.formWrapper}>
      <Input
        disabled={disabled}
        name="first_name"
        label="First Name"
        isRequired
        loading={loading}
        customStyles={styles.input}
        control={control}
        errors={errors}
        rules={{ required }}
      />
      <Input
        disabled={disabled}
        name="last_name"
        label="Last Name"
        loading={loading}
        customStyles={styles.input}
        control={control}
        errors={errors}
      />
      <Input
        disabled={disabled}
        name="phone_number"
        label="Phone Number"
        type="number"
        isRequired
        loading={loading}
        customStyles={styles.input}
        control={control}
        errors={errors}
        rules={{
          required: required,
          pattern: phoneNumberValidator
        }}
      />
      {id && (
        <>
          <Password
            onFocus={() => {
              if (isCustomer === 'true' && watch('password') === '123456A') {
                setValue('password', '');
                setDontShowPass({ ...dontShowPass, password: false });
              }
            }}
            disabled={disabled}
            loading={loading}
            customStyles={styles.input}
            name="password"
            label="Password"
            showEye={isCustomer === 'true' ? !dontShowPass?.password : true}
            errors={errors}
            control={control}
            isRequired
            rules={{
              required,
              validate: passwordValidator
            }}
            errorMapping={passwordMapping}
          />

          <Password
            onFocus={() => {
              if (isCustomer === 'true' && watch('confirm_password') === '123456A') {
                setValue('confirm_password', '');
                setDontShowPass({ ...dontShowPass, resetpassword: false });
              }
            }}
            disabled={disabled}
            loading={loading}
            customStyles={styles.input}
            name="confirm_password"
            label="Confirm Password"
            showEye={isCustomer === 'true' ? !dontShowPass?.resetpassword : true}
            errors={errors}
            control={control}
            isRequired
            rules={{
              required,
              validate: {
                isSame: (value) =>
                  (value === getValues('password') && !!value) || passwordErrors.isSame
              }
            }}
            errorMapping={[{ key: 'isSame', label: 'Matched' }]}
          />
          <Input
            disabled={disabled}
            isRequired
            name="contact_full_address"
            loading={loading}
            label="Full Address"
            control={control}
            errors={errors}
            rules={{
              required
            }}
          />
        </>
      )}
    </Box>
  );
};

export default ContactInformation;
