import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/constants';

// Function to show success toast
const showSuccessToast = (message = SUCCESS_MESSAGE) => {
  toast.success(message, {
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: styles.success
  });
};

// Function to show error toast
const showErrorToast = (message = ERROR_MESSAGE) => {
  toast.error(message, {
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: styles.error
  });
};

export { showErrorToast, showSuccessToast };
