import LinkButton from './LinkButton';
import SimpleButton from './SimpleButton';

const ButtonTypeMapping = {
  SimpleButton,
  LinkButton
};

const defaultButtonType = 'SimpleButton';

const Button = ({ as, ...rest }) => {
  const ButtonType = (as && ButtonTypeMapping[as]) ?? ButtonTypeMapping[defaultButtonType];
  return <ButtonType {...rest} />;
};

export default Button;
