import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  facilityName: '',
  country: 'india',
  state: '',
  city: '',
  street: '',
  facilityCode: '',
  zipCode: '',
  postalAddress: '',
  tehsil: '',
  status: '',
  fullAddress: '',
  isCompleted: false
};

const facilitySlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    facility: (state, action) => {
      state = Object.assign(state, action.payload);
    }
  }
});

export const { facility } = facilitySlice.actions;
export default facilitySlice.reducer;
