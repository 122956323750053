import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { Box } from '@mui/material';
import { useCallback } from 'react';
import styles from './styles';

const Validation = ({ value, name, errors, errorMapping, showError }) => {
  const getColor = useCallback(
    (key) => {
      if (
        (errors && errors[name] && !errors[name]?.types[key]) ||
        (errors && !errors[name] && value)
      ) {
        return 'success';
      }
      return showError ? 'error' : 'disabled';
    },
    [errors, name, showError, value]
  );

  return (
    <Box sx={styles.wrapper}>
      {errorMapping.map((item) => (
        <Box sx={styles.helper} key={item.key}>
          <FiberManualRecordRoundedIcon color={getColor(item.key)} sx={styles.icon} />
          <Box>{item.label}</Box>
        </Box>
      ))}
    </Box>
  );
};
export default Validation;
