import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import styles from '../styles';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: styles.toolTip
      }
    }
  }
});

const AddressRenderer = (cellData) => {
  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={cellData?.ShipToAddress} arrow placement="top">
        <Box sx={styles.address}>{cellData?.ShipToAddress}</Box>
      </Tooltip>
    </ThemeProvider>
  );
};

export default AddressRenderer;
