import { Box } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { memo } from 'react';
import NoData from '../NoData';
import styles from './styles';

const TableDataRenderer = ({
  list,
  columnDefs,
  rowHeight = 90,
  onSelectionChanged,
  rowSelection = 'multiple',
  onGridReady,
  getRowHeight
}) => {
  return list?.length ? (
    <Box className="ag-theme-alpine" sx={styles.wrapper}>
      <AgGridReact
        rowSelection={rowSelection}
        rowData={list || []}
        enableCellTextSelection
        columnDefs={columnDefs}
        rowMultiSelectWithClick
        onGridReady={onGridReady}
        suppressRowClickSelection
        wrapText
        rowHeight={rowHeight}
        animateRows
        suppressAutoSize
        autoHeight
        onSelectionChanged={onSelectionChanged}
        getRowHeight={getRowHeight}
      />
    </Box>
  ) : (
    <NoData />
  );
};

export default memo(TableDataRenderer);
