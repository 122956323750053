import React from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import styles from '../styles';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: styles.toolTip
      }
    }
  }
});

const invoiceMaker = (data) => {
  let makeString = '';
  data?.map((item, index) => (makeString += index < data?.length - 1 ? `${item}, ` : item));
  return makeString.substring(0, 12) + '...';
};

const InvoiceNumberRenderer = (data) => {
  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={
          data?.InvoNo?.length !== 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
              {data?.InvoNo?.map((item, index) => (
                <Box key={`${item + '-' + index}`}>
                  {index < data?.InvoNo?.length - 1 ? `${item},` : item}
                </Box>
              ))}
            </Box>
          )
        }
        arrow
        placement="top">
        {data?.InvoNo?.length !== 0 ? (
          <Box sx={{ display: 'flex', cursor: 'pointer' }}>{invoiceMaker(data?.InvoNo)}</Box>
        ) : (
          <Box></Box>
        )}
      </Tooltip>
    </ThemeProvider>
  );
};

export default InvoiceNumberRenderer;
