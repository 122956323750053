const dropDownIcon = {
  margin: '13px 14px 13px 0',
  position: 'relative',
  height: {
    xs: '18px',
    sm: '20px'
  },
  width: {
    xs: '18px',
    sm: '20px'
  },
  color: 'black'
};

const styles = {
  wrapper: {
    width: '100%',

    '& .user-input': {
      // borderColor: '#dedede'
    }
  },

  label: {
    height: {
      xs: '17px',
      sm: '20px'
    },
    marginBottom: {
      xs: '8px',
      sm: '12px'
    },
    color: 'custom.label',

    '& .MuiFormLabel-asterisk': {
      color: 'red'
    }
  },

  select: {
    padding: 0,
    backgroundColor: 'white',

    '& .MuiSelect-select': {
      padding: {
        xs: '15px 5px 13px',
        sm: '17px 6px 16px'
      },
      boxSizing: 'border-box'
    },

    '& .MuiBox-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    },

    '& .MuiSelect-icon': {
      display: 'none'
    },

    '& .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },

    backgroundPosition: 'calc(100% - 0.7em) center',
    backgroundRepeat: 'no-repeat'
  },

  menuItem: {
    height: {
      xs: '34px',
      sm: '42px'
    },
    color: 'black',
    p: '0 2px',
    borderBottom: '2px solid custom.connector',
    '&.Mui-selected': {
      backgroundColor: 'primary.main',
      color: 'white',

      '&:hover': {
        backgroundColor: 'primary.main',
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: '#F4BB44',
      color: 'white'
    }
  },

  text: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: '10px',
    margin: 'auto 0',
    padding: {
      xs: '0 6px',
      sm: '0 8px'
    }
  },

  paper: {
    boxShadow: '0px 0px 12px custom.scrollThumb',
    border: '1px solid',
    borderColor: 'custom.scrollThumb',
    marginTop: '5px',
    maxHeight: {
      xs: '200px',
      sm: '350px'
    }
  },

  menu: {
    padding: '0'
  },

  errorMessage: {
    marginLeft: '0 !important'
  },

  dropDownIconWrapper: {
    height: {
      xs: '35px',
      sm: '40px'
    },
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: '3px',
    cursor: 'pointer',
    transition: 'all 0.4s'
  },

  dropDownIconUp: {
    ...dropDownIcon,
    transform: 'rotate(90deg)',
    top: {
      xs: '3px',
      sm: '6px'
    }
  },

  dropDownIconDown: {
    ...dropDownIcon,
    transform: 'rotate(-90deg)',
    bottom: {
      xs: '6px',
      sm: '4px'
    }
  }
};

export default styles;
