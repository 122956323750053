import { Box, InputLabel, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MatRadio from '@mui/material/Radio';
import React from 'react';
import { Controller } from 'react-hook-form';
import { InputSkeletton } from '../../WebSkeleton';
import styles from './styles';

const Radio = ({
  disabled = false,
  name,
  control,
  label,
  options,
  customStyles,
  isRequired,
  loading = false,
  className,
  ...rest
}) => {
  if (loading) {
    return (
      <Box className={className} sx={customStyles}>
        <InputSkeletton label={label} />
      </Box>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box sx={{ ...styles.wrapper, ...customStyles }}>
          {label && <InputLabel required={isRequired}>{label}</InputLabel>}
          <RadioGroup value={field.value} onChange={field.onChange} sx={styles.radioGroup}>
            {options?.map(({ label, value }, index) => (
              <FormControlLabel
                disabled={disabled}
                key={`${label + '-' + index}`}
                label={label}
                control={<MatRadio value={value} />}
              />
            ))}
          </RadioGroup>
        </Box>
      )}
      {...rest}
    />
  );
};

export default Radio;
