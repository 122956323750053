import Box from '@mui/material/Box';
import LinkButton from '../FormComponents/Button/LinkButton';
import SimpleButton from '../FormComponents/Button/SimpleButton';
import styles from './styles';

const Form = ({
  heading,
  description,
  children,
  buttonLabel,
  buttonClick,
  linkButtonLabel,
  linkButtonClick,
  customStyles
}) => {
  return (
    <Box sx={{ ...styles.formWrapper, ...customStyles }} className="form-wrapper">
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.heading}>{heading}</Box>
        {linkButtonLabel && (
          <LinkButton
            label={linkButtonLabel}
            onClick={linkButtonClick}
            customStyles={styles.linkButton}
          />
        )}
        {buttonLabel && (
          <SimpleButton label={buttonLabel} onClick={buttonClick} customStyles={styles.button} />
        )}
        {description && <Box>{description}</Box>}
      </Box>
      <Box sx={styles.divider}></Box>
      <Box sx={styles.child}>{children}</Box>
    </Box>
  );
};

export default Form;
