const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    height: '100%'
  },

  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  heading: {
    fontSize: '24px',
    fontWeight: 500
  },

  buttonWrapper: {
    display: 'flex',
    gap: 2
  },

  button: {
    height: {
      xs: '34px',
      sm: '38px'
    },
    fontSize: '14px',
    width: 'max-content',
    borderRadius: '8px'
  },

  deActivateButton: {
    height: {
      xs: '34px',
      sm: '38px'
    },
    fontSize: '14px',
    width: 'max-content',
    backgroundColor: 'black',
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: 'black'
    }
  }
};

export default styles;
