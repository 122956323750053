import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Popover } from '@mui/material';
import { useState } from 'react';
import styles from './styles';

const AdditionalInfo = ({ children, customStyles }) => {
  const [eyeAnchor, setEyeAnchor] = useState(null);

  const handleClick = (event) => {
    setEyeAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setEyeAnchor(null);
  };

  const open = !!eyeAnchor;
  const id = open ? 'additional-info-popover' : undefined;

  return (
    <Box sx={customStyles}>
      <IconButton
        sx={styles.infoIconBtn}
        aria-describedby={id}
        aria-label="info"
        onClick={handleClick}
        className="icon-btn">
        <InfoOutlinedIcon sx={styles.infoIcon} className="icon" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={eyeAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Box sx={styles.summary}>{children}</Box>
      </Popover>
    </Box>
  );
};
export default AdditionalInfo;
