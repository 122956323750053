import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CustomPopover from '../../common/PopOver';
import styles from './styles';

const Navbar = ({ showStepper, setShowStepper, yms, warehouse, orderType }) => {
  const navigate = useNavigate();

  const { control, watch, setValue } = useForm({
    defaultValues: {
      yms: '',
      warehouse: '',
      orderType: ''
    },
    mode: 'all'
  });

  useEffect(() => {
    setValue('warehouse', warehouse);
    setValue('yms', yms);
    setValue('orderType', orderType);
  }, [yms, warehouse, orderType]);

  const warehouseValue = watch('warehouse');
  const orderTypeValue = watch('orderType');

  useEffect(() => {
    if (warehouseValue && localStorage.getItem('facility_id') !== warehouseValue) {
      localStorage.setItem('facility_id', warehouseValue);
      window?.location?.reload(false);
    }
  }, [warehouseValue]);

  useEffect(() => {
    if (orderTypeValue && localStorage.getItem('order_type') !== orderTypeValue) {
      localStorage.setItem('order_type', orderTypeValue);
      window?.location?.reload(false);
    }
  }, [orderTypeValue]);

  return (
    <Box sx={styles.navBarWrapper}>
      <Box sx={styles.navBarIcon}>
        <Box sx={styles.logoWrapper}>
          <IconButton onClick={() => setShowStepper(!showStepper)} sx={styles.menuWrapper}>
            <MenuOutlinedIcon sx={styles.icon} />
          </IconButton>
          <Box
            onClick={() => navigate('/')}
            component="img"
            src="/losungLogo.png"
            alt="losung logo"
            sx={styles.navBarLogo}
          />
          <Box sx={styles.divider} />
        </Box>
        <IconButton onClick={() => navigate(-1)} sx={styles.menuWrapper}>
          <ArrowBackIcon sx={styles.icon} />
        </IconButton>
      </Box>
      <Box sx={styles.iconWrapper}>
        <IconButton>
          <SettingsIcon sx={styles.icon} />
        </IconButton>
        <CustomPopover icon={<ExitToAppIcon sx={styles.icon} />}>
          <Box
            onClick={() => {
              window.location.reload();
              localStorage.clear();
            }}
            sx={styles.logOutWrapper}>
            Log Out
          </Box>
        </CustomPopover>
      </Box>
    </Box>
  );
};

export default Navbar;
