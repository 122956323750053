const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    gap: 2,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 30px',
    border: '1px solid #E1E3E5',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px'
  },

  text: {
    fontSize: '18px',
    fontWeight: 400
  }
};

export default styles;
