import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Form from '../../common/Form';
import { Spinner } from 'components/common';

const MainPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('vendor-list');
  }, []);

  return <Spinner />;
};

export default MainPage;
