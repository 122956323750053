import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './router/AppRouter';
import theme from './styles/theme';
import { AlertMessage } from './components/common';
import { BAD_REQUEST, SUCCESS } from '../src/constants/apiStatusCodes';
import { checkValidUser } from './redux/APICaller/vendorManagementAPIs';
import { showErrorToast } from 'components/common/Toaster';

const queryClient = new QueryClient();

const App = () => {
  const [facilityFetched, setFacilityFetched] = useState(false);

  const getUserDetails = async () => {
    const res = await checkValidUser({ page: 0, size: 1 });
    if (res?.status === SUCCESS) {
      setFacilityFetched(true);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
      localStorage.clear();

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const isLoggedIn = !!localStorage.getItem('wms_access_token');
  useEffect(() => {
    if (isLoggedIn) getUserDetails();
  }, [isLoggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <AppRouter facilityFetched={facilityFetched} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AlertMessage />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
