import { postAPI } from '../../helpers/API';
import { AUTH_PATHS } from '../../constants/apiPaths';

const { SIGN_IN, SIGN_UP, FIND_YOUR_ACCOUNT, OTP_VERIFY, RESET_PASSWORD, SETUP_PASSWORD } =
  AUTH_PATHS;

/**
 * User SignUp API
 * @param {*} data
 */
export function userSignUp(data) {
  return postAPI({
    url: SIGN_UP,
    data,
    isPrivate: false,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * User Login API
 * @param {*} data
 */
export const userLogin = async (data) => {
  return postAPI({ url: SIGN_IN, data, isPrivate: false });
};

// export function userLogin(data) {
//   return postAPI({ url: SIGN_IN, data, isPrivate: false });
// }

/**
 * User forgot password
 * @param {*} data
 */
export function userForgotPassword(data) {
  return postAPI({ url: FIND_YOUR_ACCOUNT, data, isPrivate: false });
}

/**
 * User OTP Verification
 * @param {*} data
 */
export function userOtpVerify(data) {
  return postAPI({ url: OTP_VERIFY, data, isPrivate: false });
}

/**
 * User Reset Password
 * @param {*} data
 */
export function userResetPassword(data) {
  return postAPI({ url: RESET_PASSWORD, data, isPrivate: false });
}

/**
 * User Reset Password
 * @param {*} data
 */
export function usersetupPassword(data) {
  return postAPI({
    url: SETUP_PASSWORD,
    data,
    isPrivate: false,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
