import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  warehouseName: '',
  warehouseCode: '',
  facilityId: '',
  supplierId: '',
  econSiteCode: '',
  manageCategory: '',
  supplierName: '',
  categoryName: '',
  categoryStatus: '',
  status: ''
};

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    warehouse: (state, action) => {
      state = Object.assign(state, action.payload);
    }
  }
});

export const { warehouse } = warehouseSlice.actions;
export default warehouseSlice.reducer;
