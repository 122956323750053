import { Box } from '@mui/material';
import { AlertMessage } from 'components/common';
import usePlatformAndResolution from 'hooks/usePlatformAndResolution';
import styles from './styles';

const AuthLayout = ({ children }) => {
  const { platform } = usePlatformAndResolution();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.imageWrapper}>
        <Box component="img" src="/warehouse.png" alt="warehouse" sx={styles.image} />
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.logoWrapper}>
          <Box component="img" src="/losungLogo.png" alt="losung_logo" sx={styles.logo} />
        </Box>
        <Box sx={styles.children}>{children}</Box>
      </Box>
      <AlertMessage />
    </Box>
  );
};

export default AuthLayout;
