import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { Navigate } from 'react-router-dom';
import { setAppDisable } from 'redux/authSlice';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('wms_access_token');
  const isLoggedIn = !!token;

  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/sign-in" />;
  }
};

export default PrivateRoute;
