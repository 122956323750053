import { Box } from '@mui/material';
import DynamicButton from '../FormComponents/Button';
import styles from './styles';

const FormWrapper = ({
  heading,
  buttonLabel = 'Save',
  deactivateLabel = 'Deactivate',
  deactivateClick,
  buttonClick,
  children,
  loading,
  customStyles,
  childrenStyles,
  disabled
}) => {
  return (
    <Box sx={{ ...styles.container, ...customStyles }}>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.heading}>{heading}</Box>
        <Box sx={styles.buttonWrapper}>
          {deactivateClick && (
            <DynamicButton
              disabled={loading || disabled}
              label={deactivateLabel}
              customStyles={styles.deActivateButton}
              onClick={deactivateClick}
            />
          )}
          {buttonClick && (
            <DynamicButton
              disabled={loading || disabled}
              label={buttonLabel}
              customStyles={styles.button}
              onClick={buttonClick}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ ...styles.container, ...childrenStyles }}>{children}</Box>
    </Box>
  );
};

export default FormWrapper;
