const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    width: '80%'
  },

  heading: {
    fontSize: {
      xs: '26px',
      sm: '28px',
      md: '30px'
    },
    color: '#000000',
    fontWeight: 600
  },

  description: {
    fontSize: {
      xs: '14px',
      md: '16px'
    },
    color: '#707070'
  },

  otpWrapper: {
    display: 'flex',
    gap: '20px'
  },

  otp: {
    width: '28%',
    height: '70px',
    textAlign: 'center',
    borderRadius: '12px',
    border: '2px solid #EDEDED',
    fontSize: '30px'
  },

  inputWrapper: {
    display: 'flex',
    gap: '30px',
    flexDirection: 'column',
    alignItems: 'center'
  },

  splitWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },

  input: {
    width: '100%'
  },

  splitInput: {
    width: '46%'
  },

  authWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  linkers: {
    color: '#E9B519',
    cursor: 'pointer',
    fontSize: {
      xs: '12px',
      sm: '14px'
    }
  },

  button: {
    width: '100%'
  },

  footer: {
    marginTop: '-1.5rem',
    color: '#707070',
    textAlign: 'center',
    fontSize: {
      xs: '12px',
      sm: '14px'
    }
  },

  footerLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default styles;
