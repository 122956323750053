import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: null,
  email: '',
  password: '',
  confirm_password: '',
  language: 'english',
  status: false,
  first_name: '',
  last_name: '',
  phone_number: ''
};

const createUserSlice = createSlice({
  name: 'createUser',
  initialState,
  reducers: {
    createUser: (state, action) => {
      state = Object.assign(state, action.payload);
    },
    deleteUser: () => {
      return initialState;
    }
  }
});

export const { createUser, deleteUser } = createUserSlice.actions;

export default createUserSlice.reducer;
