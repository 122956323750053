const styles = {
  wrapper: {
    '& .MuiFormLabel-asterisk': {
      color: 'red'
    }
  },

  radioGroup: {
    marginTop: {
      xs: '14px',
      md: '20px'
    }
  }
};

export default styles;
