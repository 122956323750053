import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  company_name: '',
  email: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  contact_full_address: '',
  country: '',
  state: '',
  zip_code: '',
  city: '',
  billing_full_address: '',
  isCompleted: false
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    userProfile: (state, action) => {
      state = Object.assign(state, action.payload);
    }
  }
});

export const { userProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
