const styles = {
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: {
      xs: '15px',
      sm: '30px'
    }
  },

  input: {
    width: {
      xs: '47%',
      sm: '31%'
    }
  },

  radioButton: {
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row'
    },
    width: {
      xs: '47%',
      md: '30%'
    }
  },

  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 3
  },

  modalIcon: {
    fontSize: '50px'
  }
};

export default styles;
