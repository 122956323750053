import { createSlice } from '@reduxjs/toolkit';
import { ERROR_MSG, SUCCESS_MSG, ERROR_MESSAGE } from '../constants/constants';

const initialState = {
  isOpen: false,
  messageType: '', // should be 'SUCCESS' || 'ERROR' only
  message: ''
};

export const showToastMessageSlice = createSlice({
  name: 'showMessageStore',
  initialState,
  reducers: {
    showToastMessage: (state, action) => {
      if (action.payload.messageType && action.payload.message) {
        state.isOpen = true;
        state.messageType = action.payload.messageType;
        state.message = action.payload.message;
      } else if (action.payload.messageType === ERROR_MSG && !action.payload.message) {
        state.isOpen = true;
        state.messageType = action.payload.messageType;
        state.message = ERROR_MESSAGE;
      }
    },
    closeToastMessage: (state) => {
      state.isOpen = false;
      state.messageType = '';
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        ({ type }) => type.endsWith('/rejected'),
        (state, action) => {
          state.isOpen = true;
          state.messageType = ERROR_MSG;
          state.message = action?.payload?.data?.message || ERROR_MESSAGE;
        }
      )
      .addMatcher(
        ({ type }) => type.endsWith('/fulfilled'),
        (state, action) => {
          if (action.payload.data?.message) {
            state.isOpen = true;
            state.messageType = SUCCESS_MSG;
            state.message = action?.payload?.data?.message;
          }
        }
      );
  }
});

export const { showToastMessage, closeToastMessage } = showToastMessageSlice.actions;

export const selectToastStore = (state) => state.showMessageStore;

export default showToastMessageSlice.reducer;
