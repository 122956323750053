import { Box, InputBase } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import styles, { shrinkSx } from '../styles';

const InputSkeletton = ({
  label,
  animation = 'wave',
  children,
  customStyles,
  shrink = false,
  ...rest
}) => {
  return (
    <Box>
      <Skeleton
        sx={{
          ...shrinkSx(shrink),
          ...styles.inputLabel
        }}
        animation={animation}
        {...rest}>
        <Box>{label}</Box>
      </Skeleton>
      <Skeleton
        animation={animation}
        sx={{
          ...shrinkSx(shrink),
          ...styles.inputBase
        }}
        {...rest}>
        <InputBase />
      </Skeleton>
    </Box>
  );
};

export default InputSkeletton;
