const styles = {
  wrapper: {
    width: '100%',
    height: 520,
    minHeight: 520,
    overflowY: 'auto',
    marginBottom: '1.5rem',
    fontFamily: "'Poppins', sans-serif",
    '& .ag-header': {
      height: '75px !important',
      minHeight: '75px !important',
      backgroundColor: '#F8F8F9',
      borderBottom: '1px solid #EBEBEB'
    },

    '& .ag-row': {
      borderBottom: '1px solid #EBEBEB'
    },

    '& .ag-header-cell': {
      height: '70px !important'
    },

    '& .ag-header-cell-text': {
      fontWeight: 500,
      fontSize: '16px'
    },

    '& .ag-cell': {
      fontSize: '14.5px',
      display: 'flex',
      alignItems: 'center'
    },

    '& .ag-root-wrapper': {
      borderRadius: '12px',
      borderColor: '#EBEBEB'
    }
  }
};

export default styles;
