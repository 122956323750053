import { Box } from '@mui/material';
import { Input } from 'components/common';
import { emailValidator, required } from '../../../common/commonFunctions';
import styles from '../styles';

const AccountInformation = ({ control, errors, loading = false, id, disabled }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        disabled={id}
        name="organization_name"
        label="Organisation Name"
        isRequired
        loading={loading}
        customStyles={styles.input}
        errors={errors}
        control={control}
        rules={{ required }}
      />
      <Input
        disabled={id}
        name="email"
        label="Email"
        isRequired
        loading={loading}
        customStyles={styles.input}
        errors={errors}
        control={control}
        rules={{
          required,
          pattern: emailValidator
        }}
      />
    </Box>
  );
};

export default AccountInformation;
