import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, FormHelperText, InputBase } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { InputSkeletton } from '../../WebSkeleton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { getError } from '../../../../constants/common';
import Validation from './Validation';
import styles from './styles';

const Password = ({
  name,
  control,
  label,
  variant = 'standard',
  loading = false,
  fullWidth = true,
  errors,
  rules,
  customStyles,
  errorMapping,
  isRequired,
  className,
  placeholder,
  disabled,
  startIcon,
  onFocus,
  showEye,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [handleBlur, setHandleBlur] = useState(false);
  const error = getError(name, errors);
  const showError = !!error;

  if (loading) {
    return (
      <Box className={className} sx={customStyles}>
        <InputSkeletton label={label} />
      </Box>
    );
  }

  return (
    <Controller
      render={({ field }) => (
        <FormControl
          sx={{ ...customStyles, ...styles.wrapper }}
          fullWidth={fullWidth}
          variant={variant}
          className={className}>
          <InputLabel required={isRequired} shrink className="label" sx={styles.label}>
            {label}
          </InputLabel>
          <InputBase
            onFocus={onFocus}
            disabled={disabled}
            fullWidth={fullWidth}
            placeholder={placeholder}
            startAdornment={startIcon}
            value={field.value}
            sx={styles.input}
            onChange={field.onChange}
            autoComplete="off"
            onBlur={() => {
              field.onBlur();
              setHandleBlur(true);
            }}
            type={showPassword ? 'text' : 'password'}
            inputRef={field.ref}
            error={showError}
            endAdornment={
              showEye && (
                <InputAdornment position="start">
                  <IconButton
                    disabled={disabled}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    sx={styles.iconBtn}>
                    {showPassword ? (
                      <VisibilityOutlinedIcon sx={styles.icon} />
                    ) : (
                      <VisibilityOffOutlinedIcon sx={styles.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }
          />
          {errorMapping && (
            <Box id="password-helper-text">
              <Validation
                value={field.value}
                errorMapping={errorMapping}
                showError={showError && handleBlur}
                name={name}
                errors={errors}
              />
            </Box>
          )}
          {error && !errorMapping && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
      name={name}
      rules={rules}
      control={control}
      {...rest}
    />
  );
};
export default Password;
