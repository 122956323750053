export const number = ['-', '+', 'e', 'E'];
export const text = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
export const specialCharacter = [
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '<',
  '>',
  '?',
  ':',
  ',',
  '.',
  '/',
  "'",
  ';',
  '{',
  '}',
  '[',
  ']',
  '|',
  '-',
  '_',
  '+',
  '=',
  '""'
];
