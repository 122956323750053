const styles = {
  inputLabel: {
    mb: {
      xs: '8px',
      sm: '12px'
    },
    height: { xs: '10px', sm: '14px' }
  },
  inputBase: {
    maxWidth: '100%'
  }
};

export const shrinkSx = (shrink) => {
  return shrink
    ? {}
    : {
        transform: 'none'
      };
};

export default styles;
