import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  supplierList: [],
  productCategoryList: []
};

export const appSlice = createSlice({
  name: 'appStore',
  initialState,
  reducers: {
    setSupplierList: (state, action) => {
      state.supplierList = action.payload;
    },
    setProductCategoryList: (state, action) => {
      state.productCategoryList = action.payload;
    }
  }
});

export const { setSupplierList, setProductCategoryList } = appSlice.actions;

export const selectAppStore = (state) => state.appStore;

export default appSlice.reducer;
