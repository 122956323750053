import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SUCCESS } from '../constants/apiStatusCodes';
import {
  userForgotPassword,
  userLogin,
  userOtpVerify,
  userResetPassword
} from './APICaller/authAPIs';

const initialState = {
  status: 'idle',
  isLoggedIn: false,
  roles: [],
  email: '',
  forgotPasswordSteps: 1,
  reset_token: '',
  applicationType: '',
  warehouseLocation: '',
  applicationTypeList: [],
  warehouseLocationList: [],
  orderTypeList: [],
  orderType: '',
  facility_id: '',
  productsIds: null,
  appDisable: true
};

export const userLoginThunk = createAsyncThunk('user/userLogin', async (payload, thunkAPI) => {
  try {
    const response = await userLogin(payload);
    if (response?.status === SUCCESS) {
      if (response.data.access_token && response.data.scope) {
        localStorage.setItem('wms_access_token', response.data.access_token);
        localStorage.setItem('wms_access_roles', response.data.scope);

        if (response.data.refresh_token)
          localStorage.setItem('wms_refresh_token', response.data.refresh_token);

        return response;
      }
    }
    return thunkAPI.rejectWithValue(response);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const userForgotPasswordThunk = createAsyncThunk(
  'user/forgotPassword',
  async ({ payload, isNextStep = true }, thunkAPI) => {
    try {
      const response = await userForgotPassword(payload);

      if (response?.status === SUCCESS) {
        if (response?.data?.message) {
          return { ...response, isNextStep };
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userOtpVerifyThunk = createAsyncThunk('user/otpVerify', async (payload, thunkAPI) => {
  try {
    const response = await userOtpVerify(payload);

    if (response?.status === SUCCESS) {
      if (response?.data?.message && response?.data['reset-token']) {
        return response;
      }
    }
    return thunkAPI.rejectWithValue(response);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const userResetPasswordThunk = createAsyncThunk(
  'user/resetPassword',
  async ({ payload, navigate }, thunkAPI) => {
    try {
      const response = await userResetPassword(payload);
      if (navigate) {
        navigate('/sign-in');
        return response;
      }

      if (response?.status === SUCCESS) {
        if (response?.data?.message && response?.data?.reset_token) {
          return response;
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: 'authStore',
  initialState,
  reducers: {
    loggedIn: (state, action) => {
      state.isLoggedIn = true;
      state.roles = action.payload;
    },
    forgotPasswordNextStep: (state, action) => {
      state.forgotPasswordSteps = ++state.forgotPasswordSteps;
      if (action.payload) state.email = action.payload;
    },
    clearEmailAndForgotStep: (state) => {
      state.forgotPasswordSteps = 1;
      state.email = '';
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem('wms_access_token');
    },
    setEmail: (state, action) => {
      if (action.payload) state.email = action.payload;
    },
    setWarehouseLocationAndType: (state, action) => {
      if (action.payload.applicationType) {
        state.applicationType = action.payload.applicationType;
      }
      if (action.payload.warehouseLocation) {
        state.warehouseLocation = action.payload.warehouseLocation;
      }
      if (action.payload.orderType) {
        state.orderType = action.payload.orderType;
      }
    },
    setAppDisable: (state, action) => {
      if (action.payload) {
        state.appDisable = action.payload.appDisable;
      }
    },
    setWarehouseLocationAndTypeList: (state, action) => {
      if (action.payload.applicationTypeList) {
        state.applicationTypeList = action.payload.applicationTypeList;
      }
      if (action.payload.warehouseLocationList) {
        state.warehouseLocationList = action.payload.warehouseLocationList;
      }
      if (action.payload.orderTypeList) {
        state.orderTypeList = action.payload.orderTypeList;
      }
    },
    updateProductIdsList: (state, action) => {
      state.productsIds = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // userLoginThunk
      .addCase(userLoginThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userLoginThunk.fulfilled, (state) => {
        state.isLoggedIn = true;
        state.status = 'idle';
      })
      .addCase(userLoginThunk.rejected, (state) => {
        state.status = 'idle';
      })

      // userForgotPasswordThunk
      .addCase(userForgotPasswordThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userForgotPasswordThunk.fulfilled, (state, action) => {
        if (action?.payload?.isNextStep) state.forgotPasswordSteps = ++state.forgotPasswordSteps;
        state.status = 'idle';
      })
      .addCase(userForgotPasswordThunk.rejected, (state) => {
        state.status = 'idle';
      })

      // userOtpVerifyThunk
      .addCase(userOtpVerifyThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userOtpVerifyThunk.fulfilled, (state, action) => {
        state.forgotPasswordSteps = ++state.forgotPasswordSteps;
        state.reset_token = action?.payload?.data['reset-token'];
        state.status = 'idle';
      })
      .addCase(userOtpVerifyThunk.rejected, (state) => {
        state.status = 'idle';
      })

      //userResetPasswordThunk
      .addCase(userResetPasswordThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userResetPasswordThunk.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(userResetPasswordThunk.rejected, (state) => {
        state.status = 'idle';
      })

      // Logout with 401
      .addMatcher(
        ({ type }) => type.endsWith('/rejected'),
        (state, action) => {
          if (action?.payload?.redirectTo && state.isLoggedIn) state.isLoggedIn = false;
        }
      );
  }
});

export const {
  forgotPasswordNextStep,
  clearEmailAndForgotStep,
  loggedIn,
  setEmail,
  setWarehouseLocationAndType,
  setWarehouseLocationAndTypeList,
  updateProductIdsList,
  setAppDisable
} = authSlice.actions;

export const selectAuthStore = (state) => state.authStore;

export default authSlice.reducer;
