import { Button } from '@mui/material';
import styles from './styles';

const LinkButton = ({ label, variant = 'outlined', customStyles, disabled, ...rest }) => {
  return (
    <Button
      sx={{ ...styles.wrapper, ...customStyles }}
      variant={variant}
      disabled={disabled}
      {...rest}>
      {label}
    </Button>
  );
};

export default LinkButton;
