import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Dropdown, Input } from 'components/common';
import {
  countryListConverter,
  stateListConverter,
  cityListConverter
} from 'constants/apiDataChanger';
import { useEffect } from 'react';
import { getCountryList, getStateList, getCityList } from 'redux/APICaller/vendorManagementAPIs';
import { required } from '../../../common/commonFunctions';
import { useParams } from 'react-router-dom';
import styles from '../styles';

const BillingAddress = ({
  control,
  errors,
  loading = false,
  watch,
  setValue,
  disabled,
  vendorDetails
}) => {
  const { isCustomer } = useParams();

  const { data: countryData = { data: [] } } = useQuery({
    queryKey: 'country',
    queryFn: () => {
      return getCountryList();
    }
  });

  const { data: stateData = { data: [] } } = useQuery({
    queryKey: ['state', watch('country_id')],
    queryFn: () => {
      return getStateList({ id: watch('country_id') });
    },
    enabled: !!watch('country_id')
  });

  const { data: cityData = { data: [] } } = useQuery({
    queryKey: ['city', watch('state_id')],
    queryFn: () => {
      return getCityList({ id: watch('state_id') });
    },
    enabled: !!watch('state_id')
  });

  useEffect(() => {
    if (
      (isCustomer && vendorDetails && watch('country_id') !== vendorDetails?.country_id) ||
      (watch('country_id') && !isCustomer)
    ) {
      setValue('state_id', '');
      setValue('city_id', '');
      setValue('zip_code', '');
    }
  }, [watch('country_id'), isCustomer, vendorDetails]);

  useEffect(() => {
    if (
      (isCustomer && vendorDetails && watch('state_id') !== vendorDetails?.state_id) ||
      (watch('state_id') && !isCustomer)
    ) {
      setValue('city_id', '');
      setValue('zip_code', '');
    }
  }, [watch('state_id'), isCustomer, vendorDetails]);

  useEffect(() => {
    if (
      (isCustomer && vendorDetails && watch('city_id') !== vendorDetails?.city_id) ||
      (watch('city_id') && !isCustomer)
    ) {
      setValue('zip_code', '');
    }
  }, [watch('city_id'), isCustomer, vendorDetails]);

  return (
    <Box sx={styles.formWrapper}>
      <Dropdown
        disabled={disabled}
        name="country_id"
        label="Country"
        errors={errors}
        options={countryListConverter(countryData?.data)}
        control={control}
        customStyles={styles.input}
        isRequired
        loading={loading}
        rules={{ required }}
      />

      <Dropdown
        disabled={!watch('country_id') || disabled}
        name="state_id"
        label="State"
        control={control}
        errors={errors}
        customStyles={styles.input}
        isRequired
        loading={loading}
        rules={{ required }}
        options={stateListConverter(stateData?.data)}
      />
      <Dropdown
        name="city_id"
        label="City"
        isRequired
        loading={loading}
        disabled={!watch('country_id') || !watch('state_id') || disabled}
        customStyles={styles.input}
        control={control}
        errors={errors}
        rules={{ required }}
        options={cityListConverter(cityData?.data)}
      />
      <Input
        name="zip_code"
        label="Zip Code"
        type="number"
        isRequired
        disabled={!watch('country_id') || !watch('state_id') || !watch('city_id') || disabled}
        loading={loading}
        customStyles={styles.input}
        control={control}
        errors={errors}
        rules={{
          validate: {
            minDigits: (value) =>
              (value?.length >= 5 && !!value) || 'There should be minimum 5 digits'
          }
        }}
      />
      <Input
        disabled={disabled}
        isRequired
        name="billing_full_address"
        label="Full Address"
        loading={loading}
        control={control}
        errors={errors}
        rules={{ required }}
      />
    </Box>
  );
};

export default BillingAddress;
