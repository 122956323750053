import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import styles from './styles';

const CustomCheckbox = ({ name, control, label, customStyles, ...rest }) => {
  return (
    <Controller
      render={({ field }) => (
        <FormControlLabel
          sx={{ ...styles.wrapper, ...customStyles }}
          control={
            <Checkbox defaultChecked={field.value} onChange={field.onChange} inputRef={field.ref} />
          }
          label={label}
        />
      )}
      name={name}
      control={control}
      {...rest}
    />
  );
};
export default CustomCheckbox;
