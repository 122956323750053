const styles = {
  mainPopover: {
    '& .MuiPopover-paper': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;',
      border: '1px solid',
      borderColor: '#BBC0C1'
    }
  }
};

export default styles;
