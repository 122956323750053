const styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: {
      xs: '8px',
      sm: '10px'
    },
    rowGap: {
      xs: '1px',
      sm: '2px'
    },
    columnGap: {
      xs: '3px',
      sm: '5px'
    }
  },

  helper: {
    display: 'flex',
    width: 'max-content',
    alignItems: 'center',
    gap: {
      xs: '3px',
      sm: '5px'
    }
  },

  // icon style computes same height and width w.r.t skeletonIcon
  icon: {
    height: {
      xs: '9px',
      sm: '12px'
    },
    width: {
      xs: '9px',
      sm: '12px'
    }
  },

  skeletonIcon: {
    height: {
      xs: '6px',
      sm: '8px'
    },
    width: {
      xs: '6px',
      sm: '8px'
    }
  },

  skeletonLabel: {
    width: 'max-content'
  }
};

export default styles;
