const styles = {
  success: {
    backgroundColor: '#E9F7EE',
    border: '1px solid #C1E2C9',
    fontWeight: 500
  },

  error: {
    backgroundColor: '#FCECE9',
    border: '1px solid #ECCAC3',
    fontWeight: 500
  }
};

export default styles;
