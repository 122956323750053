const styles = {
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    height: '100vh'
  },

  drawerPaper: {
    '& .MuiDrawer-paper': {
      zIndex: 100,
      position: 'fixed',
      width: {
        xs: '250px',
        md: '270px'
      },
      maxWidth: {
        xs: '300px',
        md: '340px'
      },
      borderRadius: 0,
      borderTop: 'none',
      borderBottom: 'none',
      top: 62,
      backgroundColor: 'white',
      height: `calc(100% - 62px)`
    }
  },

  drawerContent: {
    overflow: 'auto',
    position: 'fixed',
    top: 62,
    height: 'calc(100% - 62px)',
    backgroundColor: '#FBFBFD',
    transition: '300ms'
  },

  content: {
    padding: {
      xs: 2,
      sm: 4
    }
  }
};

export default styles;
