const VENDORS_PATHS = {
  CHECK_VALID_USER: '/vendors',
  VENDOR_ENABLE_DISABLE: '/vendors/:id/status',
  GET_VENDOR_DETAILS: '/vendors/:id/profile-setup/:isCustomer',
  GET_COUNTRY_LIST: '/countries',
  GET_STATE_LIST: '/countries/:id/states',
  GET_CITY_LIST: '/states/:id/cities',
  ACTIVE_VENDOR: '/vendors/:id/activate'
};

export default VENDORS_PATHS;
