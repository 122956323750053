const AUTH_PATHS = {
  // SIGN_IN: `/realms/wms-qa/protocol/openid-connect/token`,
  // SIGN_IN: `https://dev-api.wms.losung360.com/auth/login`,
  SIGN_IN: '/login',
  SIGN_UP: '/company',
  FIND_YOUR_ACCOUNT: `/reset-password`,
  OTP_VERIFY: `/verify-otp`,
  RESET_PASSWORD: `/password-reset`,
  SETUP_PASSWORD: `/company/password`
};

export default AUTH_PATHS;
